import { getContentTypeConfig } from '@/components/RichContent/PageBuilder/config';
import { isHTMLElement } from './helpers';
import { customContentType } from './contentModifications';
import type { ContentTypeStructure, ContentTypes, TResolveDomStructure } from '../types';
import createReplacementElement from './create-replacement-element';

const pbStyleAttribute = 'data-pb-style';
const bodyId = 'html-body';
const SIZE_CHART_IDS = ['size-chart', 'ww-size-chart'];

const createContentTypeObject = (type: string, node?: Element): ContentTypeStructure => ({
    appearance: node?.getAttribute('data-appearance') || null,
    children: [],
    contentType: type,
});

const walk = (
    rootEl: Node,
    contentTypeStructureObj: ContentTypeStructure,
    treeWalkerCb: (node: Node) => TreeWalker,
    resolveDOMStructure: TResolveDomStructure,
) => {
    const tree = treeWalkerCb(rootEl);

    let currentNode = tree.nextNode() as Element;
    while (currentNode) {
        if (!isHTMLElement(currentNode)) {
            currentNode = tree.nextNode() as Element;
            continue;
        }

        if (!currentNode) continue;

        const contentType: string | null = currentNode.getAttribute('data-content-type');

        if (!contentType) {
            currentNode = tree.nextNode() as Element;
            continue;
        }

        const props = createContentTypeObject(contentType, currentNode);
        const contentTypeConfig = getContentTypeConfig(<ContentTypes>contentType);

        if (contentTypeConfig && typeof contentTypeConfig.configAggregator === 'function') {
            try {
                Object.assign(
                    props,
                    contentTypeConfig.configAggregator(<HTMLElement>currentNode, props, resolveDOMStructure),
                );
            } catch (e) {
                console.error(`Failed to aggregate config for content type ${contentType}.`, e);
            }
        } else {
            console.warn(
                `Page Builder ${contentType} content type is not supported, this content will not be rendered.`,
            );
        }

        contentTypeStructureObj.children.push(props);
        walk(currentNode, props, treeWalkerCb, resolveDOMStructure);
        currentNode = tree.nextSibling() as Element;
    }

    return contentTypeStructureObj;
};

const convertToInlineStyles = (document: HTMLElement | Document) => {
    const styleBlocks = document.getElementsByTagName('style');
    const styles: { [key: string]: CSSStyleDeclaration[] } = {};
    const mediaStyles: {
        [key: string]: {
            css: string;
            selectors: string[];
        }[];
    } = {};

    if (styleBlocks.length) {
        Array.from(styleBlocks).forEach((styleBlock) => {
            const cssRules = Array.from(styleBlock.sheet?.cssRules ?? []);

            cssRules.forEach((rule: any) => {
                if ((rule as CSSStyleRule).style) {
                    const selectors = rule.selectorText.split(',').map((selector: string) => selector.trim());

                    selectors.forEach((selector: string) => {
                        if (!styles[selector]) styles[selector] = [];

                        styles[selector].push(rule.style);
                    });
                }

                if ((rule as CSSMediaRule).media) {
                    Array.from(rule.media).forEach((media: any) => {
                        const styles = Array.from(rule.cssRules).map(
                            (rule: any): { css: string; selectors: string[] } => {
                                return {
                                    css: rule.style.cssText || '',
                                    selectors: rule.selectorText.split(',').map((selector: string) => selector.trim()),
                                };
                            },
                        );
                        mediaStyles[media] = styles;
                    });
                }
            });
        });
    }

    Object.keys(mediaStyles).map((media: any, i) => {
        mediaStyles[media].forEach((style) => {
            style.selectors.forEach((selector: any) => {
                try {
                    const element = document.querySelector(selector);

                    if (element) {
                        element.setAttribute(`data-media-${i}`, media);

                        const savedStyles = element.getAttribute(`data-media-style-${i}`);
                        // avoids overwriting previously saved styles
                        element.setAttribute(
                            `data-media-style-${i}`,
                            `${savedStyles ? `${savedStyles} ` : ''}${style.css}`,
                        );
                    }
                } catch (e) {
                    return;
                }
            });
        });
    });

    Object.keys(styles).map((selector: string) => {
        try {
            const element = document.querySelector(selector) as HTMLElement;
            if (!element) {
                return;
            }

            styles[selector].map((style) => {
                element.setAttribute('style', element.style.cssText + style.cssText);
            });
            element.removeAttribute(pbStyleAttribute);
        } catch (e) {
            return;
        }
    });
};

const reinjectScripts = (document: HTMLElement | Document) => {
    document.querySelectorAll('script[src]').forEach((scriptElement) => {
        scriptElement.replaceWith(createReplacementElement(scriptElement));
    });
};

const parseStorageHtml = (
    htmlStr: string,
    props: { identifier?: string; type?: string },
    resolveDOMStructure: TResolveDomStructure,
) => {
    const { identifier, type } = props;
    let content = htmlStr;

    if (type === 'cmsPage' && identifier && SIZE_CHART_IDS.includes(identifier)) {
        content = `<div data-content-type="page-size-chart">${htmlStr}</div>`;
    }

    const document = resolveDOMStructure(content);
    const stageContentType = createContentTypeObject('root-container');

    customContentType(document);

    document.body.id = bodyId;
    convertToInlineStyles(document);
    reinjectScripts(document);

    return walk(document.body, stageContentType, (rootEl) => document.createTreeWalker(rootEl), resolveDOMStructure);
};

export default parseStorageHtml;
