import React from 'react';
import clsx from 'clsx';
import { collectCssStyles, verticalAlignmentToFlex } from '@/components/RichContent/PageBuilder/utils/helpers';
import { AppearanceEnum, CssClassesProps, ContentStyleProps } from '@/components/RichContent/PageBuilder/types';
import mergeClasses from '@/utils/Object/shallowMerge';
import { resourceUrl } from '@/utils/resourceUrl';

import defaultClasses from './row.module.scss';

interface IRow extends CssClassesProps {
    appearance: AppearanceEnum;
    children: any;
}

const Row = ({ appearance, children, classes: propsClasses, cssClasses = [], ...rest }: IRow) => {
    const classes = mergeClasses(defaultClasses, propsClasses);
    const {
        backgroundAttachment,
        backgroundColor,
        backgroundPosition,
        backgroundRepeat,
        backgroundSize,
        backgroundType,
        desktopImage,
        minHeight,
        mobileImage,
        verticalAlignment,
        videoFallbackSrc,
        videoLazyLoading,
        videoLoop,
        videoOverlayColor,
        videoPlayOnlyVisible,
        videoSrc,
        ...remaining
    }: ContentStyleProps = rest || {};

    const dynamicStyles = {
        backgroundColor,
        minHeight,
        ...collectCssStyles(remaining),
        ...(desktopImage && {
            backgroundAttachment,
            backgroundImage: `url(${resourceUrl(desktopImage)})`,
            backgroundPosition,
            backgroundRepeat,
            backgroundSize,
        }),
        ...(verticalAlignment && {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: verticalAlignmentToFlex(verticalAlignment),
        }),
    };

    const videoOverlay = videoOverlayColor ? (
        <div className={classes.videoOverlay} style={{ backgroundColor: videoOverlayColor }} />
    ) : null;

    if (appearance === AppearanceEnum.FULLBLEED) {
        return (
            <div
                style={{
                    ...(dynamicStyles as React.CSSProperties),
                    marginLeft: '',
                    marginRight: '',
                }}
                className={clsx(classes.fullBleed, classes.root, cssClasses)}
            >
                {videoOverlay}
                {children}
            </div>
        );
    }

    if (appearance === AppearanceEnum.FULLWIDTH) {
        return (
            <div
                style={{
                    ...(dynamicStyles as React.CSSProperties),
                    marginLeft: '',
                    marginRight: '',
                }}
                className={clsx(classes.fullBleed, classes.root, cssClasses)}
            >
                {videoOverlay}
                <div className={classes.contained}>{children}</div>
            </div>
        );
    }

    return (
        <div className={clsx(classes.contained, cssClasses)}>
            <div className={classes.inner} style={dynamicStyles as React.CSSProperties}>
                {videoOverlay}
                {children}
            </div>
        </div>
    );
};

export default Row;
