import React, { Suspense } from 'react';
import { getContentTypeConfig } from '@/components/RichContent/PageBuilder/config';

const renderContentType = (Component: Element, data: any) => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Component {...data}>
            {data.children.map((childTreeItem: any, i: number) => (
                <ContentTypesFactory key={i} data={childTreeItem} /> // eslint-disable-line
            ))}
        </Component>
    );
};

export const ContentTypesFactory = ({ data }: { data: any }) => {
    const { isHidden, ...props } = data;

    if (isHidden) {
        return null;
    }

    const contentTypeConfig = getContentTypeConfig(props.contentType);

    if (contentTypeConfig?.component) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const Component = renderContentType(contentTypeConfig.component, props);

        return <Suspense fallback={null}>{Component}</Suspense>;
    }

    return null;
};

export default ContentTypesFactory;
